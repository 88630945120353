import {createContext} from "react";

export interface EsetKey {
    key: string,
    applyTime: string
}

export interface EsetLicense { 
    userId: string,
    status: number,
    applyTime: string,
    esetKeyList: EsetKey[],
}

export interface ProfileLang {
    id?: number,
    username?: string,
    language?: number,
    applyTime?: string,
}

export interface EmailAlias { 
    id?: number,
    username?: string,
    alias?: number,
    applyTime?: string,
}

// export interface LotusNotesPW {
//     lotusNotes: string,
// }
export interface UserLicenseInfo {
    ACCOUNT_LOTUS_NOTES?: boolean,
    ACCOUNT_LOTUS_NOTES_RESULT?: string,
    ACCOUNT_TWOFA?: boolean,
    ACCOUNT_CHANGE_PROFILE_LANGUAGE?: boolean ,
    ACCOUNT_CHANGE_PROFILE_LANGUAGE_RESULT?: ProfileLang,
    LICENSE_EMAIL_ALIAS?: boolean,
    LICENSE_EMAIL_ALIAS_RESULT?: EmailAlias,
    LICENSE_ENDNOTE?: boolean,
    LICENSE_ESET_SMART?: boolean,
    LICENSE_ESET_SMART_RESULT?: EsetLicense,
    LICENSE_LIBRARY?: boolean,
    LICENSE_OPEN_DATA?: boolean,
    LICENSE_PRO_PLUS?: boolean,
    LICENSE_QUALTRICS?: boolean,
    LICENSE_WORK_AT_HOME?: boolean,
    LICENSE_UMCONNECT_STUDENT?: boolean,
    LICENSE_UMCONNECT_ALUMNI?: boolean,
    // LICENSE_ZOOM?: boolean,
    LICENSE_RESEARCH_ETHICS?: boolean,
    LICENSE_TENCENT_MEETING?: boolean,
    Service: string[]
}

const UserLicenseInfoContext = createContext<UserLicenseInfo | null >(null);
export default UserLicenseInfoContext;