import ArticleIcon from '@mui/icons-material/Article';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PieChartIcon from '@mui/icons-material/PieChart';
import HomeIcon from '@mui/icons-material/Home';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import KeyIcon from '@mui/icons-material/Key';

import EsetContent from './license_content/Eset';
import EndNoteContent from './license_content/EndNote';
import WorkAtHomeContent from './license_content/WorkAtHome';
import LibraryContent from './license_content/Library';
import Office365ProPlusContent from './license_content/Office365ProPlus';
import OpenDataContent from './license_content/OpenData';
import ZoomContent from './license_content/Zoom';
import TencentContent from './license_content/Tencent';
import QualtricsContent from './license_content/Qualtrics';
import LotusNotesContent from './license_content/LotusNotes';
import EmailAlias from './license_content/EmailAlias';
import UMConnectStudent from './license_content/UMConnectStudent';
import UMConnectAlumni from './license_content/UMConnectAlumni';
import ResearchEthicsContent from './license_content/ResearchEthics';


export const LicenseDatas = [
  {
    "id": 0,
    "name": "LICENSE_ENDNOTE",
    "title": "ENDNOTE",
    "desc": "EndNote is software that compiles a list of resources you have used in your research...",
    "bgcolor": "#9cddff",
    "icon": <AttachFileIcon />,
    "content": <EndNoteContent apply_stat="yes" />,
    "temp_content": "",
    "apply_agreement": <EndNoteContent apply_stat="no" />,
    "api": "endnote"
  },
  {
    "id": 1,
    "name": "LICENSE_PRO_PLUS",
    "title": "MICROSOFT 365",
    "desc": "Download and install Office 365 ProPlus on up to 5 PC or Mac...",
    "bgcolor": "#a4dfff",
    "icon": <ArticleIcon />,
    "content": <Office365ProPlusContent apply_stat="yes" />,
    "temp_content": "",
    "apply_agreement": <Office365ProPlusContent apply_stat="no" />,
    "api": "proplus"
  },
  {
    "id": 2,
    "name": "LICENSE_OPEN_DATA",
    "title": "OPEN DATA API PLATFORM",
    "desc": "DATA.UM.EDU.MO is an application programming interface(API) platform...",
    "bgcolor": "#b7e6ff",
    "icon": <ShareRoundedIcon />,
    "content": <OpenDataContent apply_stat="yes" />,
    "temp_content": "",
    "apply_agreement": <OpenDataContent apply_stat="no" />,
    "api": "opendata"
  },
  {
    "id": 3,
    "name": "LICENSE_ZOOM",
    "title": "ZOOM",
    "desc": "Zoom is a Cloud-Based Web Conferencing Service that is available...",
    "bgcolor": "#BFE6FB",
    "icon": <VideocamRoundedIcon/>,
    "content": <ZoomContent apply_stat="yes" />,
    "temp_content": "",
    "apply_agreement": <ZoomContent apply_stat="no" />,
    "api": "zoom"
  },
  {
    "id": 4,
    "name": "LICENSE_LIBRARY",
    "title": "MY LIBRARY ACCOUNT",
    "desc": "As the University Library system has been upgraded and replaced by a next...",
    "bgcolor": "#C6E8FB",
    "icon": <MenuBookIcon />,
    "content": <LibraryContent apply_stat="yes"/>,
    "temp_content": "",
    "apply_agreement": <LibraryContent apply_stat="no" />,
    "api": "library"
  },
  {
    "id": 5,
    "name": "LICENSE_ESET_SMART",
    "title": "ESET INTERNET SECURITY",
    "desc": "UM adopts a home license for students and staffs. An authorization process...",
    "bgcolor": "#CDEBFC",
    "icon": <RemoveRedEyeIcon />,
    "content": <EsetContent apply_stat="yes" />,
    "temp_content": <EsetContent apply_stat="newapply" />,
    "apply_agreement": <EsetContent apply_stat="no" />,
    "api": "eset_smart"
  },
  {
    "id": 6,
    "name": "LICENSE_QUALTRICS",
    "title": "QUALTRICS",
    "desc": "UM-Qualtrics is web-based survey service that is available for use...",
    "bgcolor": "#D4EEFC",
    "icon": <PieChartIcon />,
    "content": <QualtricsContent apply_stat="yes" />,
    "temp_content": "",
    "apply_agreement": <QualtricsContent apply_stat="no" />,
    "api": "qualtrics"
  },
  {
    "id": 7,
    "name": "LICENSE_WORK_AT_HOME",
    "title": "WORK AT HOME",
    "desc": "The University has subscribed to the Microsoft Campus Agreement...",
    "bgcolor": "#DBF1FD",
    "icon": <HomeIcon />,
    "content": <WorkAtHomeContent apply_stat="yes"/>,
    "temp_content": "",
    "apply_agreement": <WorkAtHomeContent apply_stat="no" />,
    "api": "workathome"
  },
  {
    "id": 8,
    "name": "ACCOUNT_TWOFA",
    "title": "TWO-FACTOR AUTHENTICATION",
    "desc": "Two-factor authentication provides an extra layer of protection...",
    "bgcolor": "#E2F4FD",
    "icon": <AdminPanelSettingsIcon />,
    "content": "",
    "temp_content": "",
    "apply_agreement": "",
    "api": "twofa"
  },
  {
    "id": 9,
    "name": "LICENSE_EMAIL_ALIAS",
    "title": "EMAIL ALIAS (UM@Connect)",
    "desc": "This email alias is an alternative email address of your UM@Connect mailbox...",
    "bgcolor": "#EAF7FE",
    "icon": <PersonIcon />,
    "content": <EmailAlias apply_stat="yes"/>,
    "temp_content": "",
    "apply_agreement": <EmailAlias apply_stat="no" />,
    "api": "email_alias"
  },
  {
    "id": 10,
    "name": "STUDENT_CHANGE_LANGUAGE",
    "title": "Change user profile language",
    "desc": "ICTO provides sets of Standard Profile including customized software settings and different languages...",
    "bgcolor": "#F1F9FE",
    "icon": <SettingsIcon />,
    "content": <EmailAlias apply_stat="yes"/>,
    "temp_content": "",
    "apply_agreement": <EmailAlias apply_stat="no" />,
    "api": "email_alias"
  },
  {
    "id": 11,
    "name": "LICENSE_UMCONNECT_STUDENT",
    "title": "UM@Connect Student",
    "desc": "All students are entitled to use UM@Connect lifelong email service.",
    "bgcolor": "#F8FCFF",
    "icon": <EmailIcon />,
    "content": <UMConnectStudent apply_stat="yes"/>,
    "temp_content": "",
    "apply_agreement": <UMConnectStudent apply_stat="no" />,
    "api": "student_um_connect"
  },
  {
    "id": 12,
    "name": "LICENSE_UMCONNECT_ALUMNI",
    "title": "UM@Connect Alumni",
    "desc": "All alumnis are entitled to use UM@Connect lifelong email service.",
    "bgcolor": "#F8FCFF",
    "icon": <EmailIcon />,
    "content": <UMConnectAlumni apply_stat="yes"/>,
    "temp_content": "",
    "apply_agreement": <UMConnectAlumni apply_stat="no" />,
    "api": "alumni_um_connect"
  },
  {
    "id": 13,
    "name": "ACCOUNT_LOTUS_NOTES",
    "title": "Lotus Notes Password",
    "desc": "Lotus Notes Workflow services including e-Bulletin, e-Procurement, e-Proposal, Document Database.",
    "bgcolor": "#E2F4FD",
    "icon": <KeyIcon />,
    "content": <LotusNotesContent apply_stat="yes"/>,
    "temp_content": "",
    "apply_agreement": <LotusNotesContent apply_stat="no" />,
    "api": ""
  },
  {
    "id": 14,
    "name": "LICENSE_RESEARCH_ETHICS",
    "title": "UM Research Ethics Review System",
    "desc": "UM Research Ethics Review System is a web-based system for handling research ethics applications.",
    "bgcolor": "#E2F4FD",
    "icon": <LibraryBooksIcon />,
    "content": <ResearchEthicsContent apply_stat="yes"/>,
    "temp_content": "",
    "apply_agreement": <ResearchEthicsContent apply_stat="no" />,
    "api": "research_ethics"
  },
  {
    "id": 15,
    "name": "LICENSE_TENCENT_MEETING",
    "title": "Tencent Meeting",
    "desc": "Tencent meeting provide alternative communication channels to our users. ",
    "bgcolor": "#E2F4FD",
    "icon": <VideocamRoundedIcon/>,
    "content": <TencentContent apply_stat="yes" />,
    "temp_content": "",
    "apply_agreement": <TencentContent apply_stat="no" />,
    "api": "tencentmeeting"
  },
]
