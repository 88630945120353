import * as React from 'react';
import { Divider, Link, Typography, Alert, Box } from '@mui/material';
import { Trans, withTranslation, useTranslation  } from 'react-i18next';
import { styled } from '@mui/material/styles'
import { Theme } from '@mui/material/styles';

interface apply_stat {
    apply_stat: string
  }

  const ChineseNumeralsTypography = styled(Typography)(({ theme }: { theme: Theme }) => ({
    listStyleType: 'none',
    counterReset: 'section',
    fontFamily: theme.typography.fontFamily,
    lineHeight: theme.typography.body1.lineHeight,
    '& li::before': {
      counterIncrement: 'section',
      content: 'counter(section, cjk-ideographic) "）"',
      marginRight: '3px',
    },
  }));

function TencentContent ({ apply_stat }: apply_stat) {
    const { t, i18n } = useTranslation();
    const isChinese = i18n.language.startsWith('zh');

    return ( 
        <Box>
            <Typography sx={{ pt: 5, fontWeight: 400}} variant='subtitle1'>
                {t("tencent.header")}
            </Typography>
            { apply_stat === "yes" ?
                <Box sx={{ mt: 4}}>
                    <Divider sx={{mt: 3, mb:2}}></Divider>
                    <Typography>
                        <Trans i18nKey="tencent.moreinfo">
                            <Link href="https://faq.icto.um.edu.mo/tag/tencent-meeting/" target="_blank" rel="noreferrer" />
                        </Trans>
                    </Typography>
                </Box>: null 
            }
            {   apply_stat === "no" ?
                <Box>
                    <Typography variant='subtitle1' sx={{ fontWeight: 600, mt: 2}}>
                        {t("common.agree_read")}
                    </Typography>
                    {isChinese ? (
                    <ChineseNumeralsTypography variant='subtitle1' as='ol' sx={{ mt: 2 , fontWeight: 400}}>
                        <Trans
                        i18nKey="tencent.agreement"
                        components={{
                            dot: <li />,
                            line: <u />,
                            a: <Link />,
                            br: <br />,
                            link1: <Link href="https://www.um.edu.mo/privacy-policy/privacy-policy-statement/" target="_blank" rel="noreferrer" />,
                        }}
                        ></Trans>
                    </ChineseNumeralsTypography>) : (
                    <Typography variant='subtitle1' component='ol' sx={{mt: 2}} >
                        <Trans i18nKey="tencent.agreement" components={{ 
                            dot: <li />, 
                            line: <u />,
                            a: <Link />,
                            br: <br />,
                            link1: <Link href="https://www.um.edu.mo/privacy-policy/privacy-policy-statement/" target="_blank" rel="noreferrer" />
                        }}></Trans>
                    </Typography>
                    )}
                    <Alert severity='warning' sx={{ mt: 2}}><Trans i18nKey="tencent.warning" components={{ s: <strong />, i: <i />}}></Trans></Alert>
                </Box>: null
            }
        </Box>
    );
};
export default withTranslation()(TencentContent);